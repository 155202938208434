<template lang="html">

  <h1>Baza Korisnika i Kompanija</h1>

  <TabNav :tabs="companyList" :selected="selected" :tabType="true" @selected="setSelected" id="companyNav">
    <Tab v-for="(company) in companyList" :key="company" :isSelected="selected === company ">

<div v-if="loading" class="loader">

</div>
<div v-if="!loading">
      <div class="validation">
        {{validation}}
      </div>
      <h2>{{selected}}</h2>
      <div class="">
        <label for="">Ime:&nbsp;&nbsp;</label>
        <input type="text" name="" v-model="searchName">
      </div>
      <div class="">
        <label for="">Sektor:&nbsp;&nbsp;</label>
        <select name="sektoriSelect"  v-model="searchSector" >
           <option  v-for="d in sektori" v-bind:key="d">{{d}}</option>
        </select>
        <button type="button" @click="searchSector=''">X</button>
      </div>
      <div class="">
        <label for="">Radno Mesto:&nbsp;&nbsp;</label>
        <select name="sektoriSelect"  v-model="searchRM" >
           <option  v-for="d in radnaMesta" v-bind:key="d">{{d}}</option>
        </select>
        <button type="button" @click="searchRM=''">X</button>
      </div>
      <div class="">
        <button type="button" name="button" @click="filter()">Filtriraj</button>
      </div>


      <!-- <input type="text" name="" v-model="searchSector"> -->



      <div class="container" v-for="(userX,j) in finalList"  :key="userX._id" >
        <table>
          <tr>
            <th>Ime</th>
            <th>Sektor</th>
            <th>Radno mesto</th>
            <th>Nadređeni</th>
            <th>Proširi</th>
          </tr>
          <tr>

            <td>
              <input type="text" name="" v-model="userX.name">
            </td>

            <td>
              <select name="sektori" id="sektori" v-model="userX.sektor" >
                 <option maxlength="14" v-for="sek in sektori" v-bind:key="sek" :value="sek">{{sek.substring(0,35)}}</option>
              </select>
            </td>
            <td>
              <select name="radM" id="radM" v-model="userX.radnoMesto" >
                 <option  v-for="rm in radnaMesta" v-bind:key="rm" :value="rm">{{rm.substring(0,35)}}</option>
              </select>
            </td>

            <td>{{userX.nadredjeni}}</td>

            <td>
              <button v-if="!prosiriList[j]" type="button" @click="activateProsiri(j)">Proširi</button>
              <button v-if="prosiriList[j]" type="button" @click="activateProsiri(j)">Skupi</button>
            </td>

          </tr>

          <tr v-if="prosiriList[j]">
            <th>Email</th>
            <th>Username</th>
            <th>Izmeni nadređenog</th>
          </tr>
          <tr v-if="prosiriList[j]">

              <td>
                <input type="text" name="" v-model="userX.email">
              </td>

              <td>
                <input type="text" name="" v-model="userX.username">
              </td>




          <td>
              <select name="collegue" id="collegue" v-model="collegue[j]" >
                 <option  v-for="collegue in usersSameCompany" v-bind:key="collegue.name">{{collegue.name}}</option>
              </select>
            </td>
          </tr>

        </table>
        <div class="" v-if="prosiriList[j]">


        <button type="button" name="button" @click="removeUser(userX._id)">Ukloni Zaposlenog</button>
        <button type="button" name="button" @click="updateNadredjeni(userX._id,collegue[j])">Izmeni Nadredjenog</button>
        <button type="button" name="button" @click="updateUserThis(userX._id,userX.name,userX.email, userX.username,userX.sektor,userX.radnoMesto )">Sacuvaj Ostale izmene</button>

        <input type="text" name="" v-model="pass[j]">
        <button type="button" name="button" @click="changePassThis(userX.username,pass[j])">Izmeni password</button>
        </div>
      </div>

    </div>
    </Tab>
  </TabNav>
  <div v-if="this.savedSuccessfully" class="bg-success p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">Promene uspešno snimljene!</div>
  <div v-if="this.saveFailed" class="bg-danger p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">Username već postoji u bazi!</div>
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
// eslint-disable-next-line no-unused-vars
import TabNav from '@/components/TabNav.vue'
import Tab from '@/components/Tab.vue'
export default {
  computed: mapGetters(['companys',"users","user",'usersSameCompany']),
  components:{
    TabNav,
    Tab,
  },
  data(){
    return{
      selected: '',
      companyList:[],
      //each element is a list of users (different lists are for different companies)
      listOfListsOfUsers:[],
      validation:"",
      tester:"",
      collegue:[],
      pass:[],
      sektori:[],
      radnaMesta:[],
      iMemory:0,
      searchName:'',
      searchSector:'',
      searchRM:'',
      finalList:[],
      prosiriList:[],
      loading:true,
      savedSuccessfully: false,
      saveFailed: false,
      usernameExists: false,
    }
  },
  methods: {
    ...mapActions(['getCompanies','getAllUsers','getProfile','deleteUser','getCollegues','setNadredjeni','changePass','updateUser']),
    async setSelected(tab){
      this.loading=true;
      this.selected=tab;
      await this.getCollegues(tab);

      let tempCompany;

      for (var i = 0; i < this.companys.length; i++) {
        if(this.companys[i].name == this.selected){
          tempCompany=this.companys[i];
        }
      }

      this.sektori=[];
      this.radnaMesta=[];

      for ( i = 0; i < tempCompany.sektori.length; i++) {
        this.sektori.push(tempCompany.sektori[i].name);
        for (var j = 0; j < tempCompany.sektori[i].radnaMesta.length; j++) {
          this.radnaMesta.push(tempCompany.sektori[i].radnaMesta[j]);
        }
      }

      //////////////
      for ( i = 0; i < this.companyList.length; i++) {
        if(this.companyList[i]==this.selected){
          this.currentCompanyName=this.companyList[i];
          this.iMemory=i;
        }
      }

      this.finalList=[];
      this.prosiriList=[];

      for ( i = 0; i < this.usersSameCompany.length; i++) {
          this.finalList.push(this.usersSameCompany[i]);
          this.prosiriList.push(false);
      }

      /////////
      this.loading=false;
    },

    activateProsiri(index){
      // console.log("ProsiriList[index]:",this.);
      this.prosiriList[index]=!this.prosiriList[index];
    },
    changePassThis(usrName,pass){
      let data={
        username:usrName,
        password:pass,
      }
      this.changePass(data);
      this.validation="Zamenjena šifra za username: "+usrName;
    },
    removeUser(userID){
      this.validation="";
      this.deleteUser(userID);
      this.validation=`Uklonjen Zaposleni id=${userID}`;
    },
    async updateNadredjeni(userID, collegueName){
      console.log(userID);
      console.log(collegueName);
      let nadredjeni_id=null;

      for (var i = 0; i < this.users.length; i++) {
        if(this.users[i].name == collegueName){
          nadredjeni_id= this.users[i]._id;
        }
      }

      let nadredjeniData={
        userID:userID,
        nadredjeniName:collegueName,
        nadredjeni_id:nadredjeni_id,

      }
      await this.setNadredjeni(nadredjeniData);
      this.validation=`Zaposlenom sa ID=${userID} postavljen nadredjeni ${collegueName}`;
    },
    notificationSuccess() {
          this.savedSuccessfully = true;
          setTimeout(() => 
            this.savedSuccessfully = false, 5000
           );
        },
      notificationFail() {
        this.saveFailed = true;
        setTimeout(() => 
          this.saveFailed = false, 5000
          );
      },
    async updateUserThis(id,nameX,emailX, usernameX,sektorX,radnoMestoX){
    let userdata={
      userID:id,
      name:nameX,
      email:emailX,
      username:usernameX,
      sektor:sektorX,
      radnoMesto:radnoMestoX,

    }    
    this.loading=true;
    await this.getAllUsers();
    for (var i = 0; i < this.users.length; i++) {
        if(this.users[i].username == userdata.username){
          this.notificationFail();
          this.usernameExists = true;
          this.loading=false;
          break;
        } else {
          this.usernameExists = false;
          this.loading=false;
        }
      }
      if(!this.usernameExists){
        this.notificationSuccess();
        await this.updateUser(userdata);
        this.validation=`Zaposlenom ${nameX} sacuvane izmene `;
        this.usernameExists = true;
      }
      
  },
  async filter(){

    this.finalList.splice(0)
    console.log(this.finalList);

    for (let i = 0; i < this.usersSameCompany.length; i++) {
      console.log("iteracija: "+i);
      let tempUser = this.usersSameCompany[i];
        if(tempUser.name.toLowerCase().includes(this.searchName.toLowerCase()) && tempUser.sektor.toLowerCase().startsWith(this.searchSector.toLowerCase()) && tempUser.radnoMesto.toLowerCase().startsWith(this.searchRM.toLowerCase())){
          console.log("Uso za usera: "+tempUser.name);
          this.finalList.push(this.usersSameCompany[i]);
        }
    }
  }
  },

  async created() {
    await this.getProfile();
    await  this.getCompanies();

    this.companys.forEach(company => {
      let list = [];
      this.listOfListsOfUsers.push(list);
      if(company.name!="a"){
      this.companyList.push(company.name);
      }
    });

  },
}
</script>

<style lang="css" scoped>

table{
  border: 2px solid black;
  text-align: center;
}
td, th{
  text-align: center;
  border: 1px solid black;
}
tr:nth-child(even){background-color: #aaaacc;}
tr:nth-child(odd){background-color: #ccccff;}
tr:hover {background-color: #afa;}

.container{
  margin-top: 10px;
}

.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}

select option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  margin:auto;
  margin-top: 100px;

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
